import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
// api
import { getStrapiUser, GetStrapiUserResponse, USER_KEYS } from "api"
// types
import { User } from "types/user"

const useStrapiUser = (token: string | null) => {
  const [user, setUser] = useState<User | Record<string, unknown>>({} as User)

  const {
    isLoading,
    isInitialLoading,
    isFetching: isUserRefetching,
    refetch: refetchUser,
  } = useQuery<GetStrapiUserResponse>({
    queryKey: [USER_KEYS.STRAPI_USER],
    queryFn: () => getStrapiUser(),
    enabled: !!token,

    onSuccess: (_user) => {
      setUser({
        id: _user.id,
        confirmed: _user.confirmed,
        userProfileId: _user.user_profile?.id,
        name: _user.user_profile?.userFirstName + " " + _user.user_profile?.userLastName,
        userName: _user.username,
        jobTitle: _user.user_profile?.userJobTitle,
        locale: _user.user_profile?.userLocale,
        pictureUrl: _user.user_profile?.userPicture instanceof Object && _user.user_profile?.userPicture?.url,
        pictureId: _user.user_profile?.userPicture instanceof Object && _user.user_profile?.userPicture?.id,
        cmePoints: _user.user_profile?.cmePoints,
        email: _user.email,
        specialty: _user.user_profile?.specialty instanceof Object && _user.user_profile?.specialty?.name,
        filterSpecialties: _user.user_profile?.filterSpecialties,
        firstName: _user.user_profile?.userFirstName,
        lastName: _user.user_profile?.userLastName,
        gender: _user.user_profile?.userGender,
        title: _user.user_profile?.title,
        userDateOfBirth: _user.user_profile?.userDateOfBirth,
        userJobPosition: _user.user_profile?.userJobPosition,
        userJobTitle: _user.user_profile?.userJobTitle,
        userEFN: _user.user_profile?.userEFN,
        userPartnercode: _user.user_profile?.userPartnercode,
        subscribeNewCourses: _user.user_profile?.subscribeNewCourses,
        subscribeYourQuestionAnswered: _user.user_profile?.subscribeYourQuestionAnswered,
        purchasedSuperCoursesIds: _user.user_profile?.purchasedSuperCourses?.map(({ id }) => id),
        subscribedPartnerChannels: _user.user_profile?.subscribedPartnerChannels,
        createdAt: _user.user_profile?.createdAt,
      })
    },
  })

  return {
    isLoading,
    isInitialLoading,
    user,
    setUser,
    isUserRefetching,
    refetchUser,
  }
}

export default useStrapiUser
