// en
import sharedEn from "pages/locales/en.json"
import mainLayoutEn from "pages/layouts/MainLayout/locales/en.json"
import settingsLayoutEn from "pages/layouts/Settings/locales/en.json"
import homeEn from "pages/Home/locales/en.json"
import detailEn from "pages/Detail/locales/en.json"
import courseEn from "pages/Course/locales/en.json"
import purchasedEn from "pages/Purchased/locales/en.json"
import favouritesEn from "pages/Favourites/locales/en.json"
import speakerEn from "pages/Speaker/locales/en.json"
import searchEn from "pages/Search/locales/en.json"
import testEn from "pages/Test/locales/en.json"
import watchedEn from "pages/Watched/locales/en.json"
import settingsEn from "pages/Settings/locales/en.json"
import entryPointEn from "pages/auth/EntryPoint/locales/en.json"
import loginEn from "pages/auth/Login/locales/en.json"
import registrationEn from "pages/auth/Registration/locales/en.json"
import emailConfirmEn from "pages/auth/EmailConfirmation/locales/en.json"
import notificationsEn from "pages/Notifications/locales/en.json"
import forgotPasswordEn from "pages/auth/ForgotPassword/locales/en.json"
import resetPasswordEn from "pages/auth/ResetPassword/locales/en.json"
import welcomeEn from "pages/auth/Welcome/locales/en.json"
import onboardingEn from "pages/auth/Onboarding/locales/en.json"
import fallbackEn from "pages/Fallback/locales/en.json"
import allCoursesEn from "pages/AllCourses/locales/en.json"
import notSeenCoursesEn from "pages/NotSeenCourses/locales/en.json"
import allEpisodesEn from "pages/AllEpisodes/locales/en.json"
import allShortUnitsEn from "pages/AllShortUnits/locales/en.json"
import allIntrosEn from "pages/AllIntros/locales/en.json"
import channelEn from "pages/Channel/locales/en.json"
import studyMonitorEn from "pages/StudyMonitor/locales/en.json"
// de
import sharedDe from "pages/locales/de.json"
import mainLayoutDe from "pages/layouts/MainLayout/locales/de.json"
import settingsLayoutDe from "pages/layouts/Settings/locales/de.json"
import homeDe from "pages/Home/locales/de.json"
import detailDe from "pages/Detail/locales/de.json"
import courseDe from "pages/Course/locales/de.json"
import purchasedDe from "pages/Purchased/locales/de.json"
import favouritesDe from "pages/Favourites/locales/de.json"
import speakerDe from "pages/Speaker/locales/de.json"
import searchDe from "pages/Search/locales/de.json"
import testDe from "pages/Test/locales/de.json"
import watchedDe from "pages/Watched/locales/de.json"
import settingsDe from "pages/Settings/locales/de.json"
import entryPointDe from "pages/auth/EntryPoint/locales/de.json"
import loginDe from "pages/auth/Login/locales/de.json"
import registrationDe from "pages/auth/Registration/locales/de.json"
import emailConfirmDe from "pages/auth/EmailConfirmation/locales/de.json"
import notificationsDe from "pages/Notifications/locales/de.json"
import forgotPasswordDe from "pages/auth/ForgotPassword/locales/de.json"
import resetPasswordDe from "pages/auth/ResetPassword/locales/de.json"
import welcomeDe from "pages/auth/Welcome/locales/de.json"
import onboardingDe from "pages/auth/Onboarding/locales/de.json"
import fallbackDe from "pages/Fallback/locales/de.json"
import allCoursesDe from "pages/AllCourses/locales/de.json"
import notSeenCoursesDe from "pages/NotSeenCourses/locales/de.json"
import allEpisodesDe from "pages/AllEpisodes/locales/de.json"
import allShortUnitsDe from "pages/AllShortUnits/locales/de.json"
import allIntrosDe from "pages/AllIntros/locales/de.json"
import channelDe from "pages/Channel/locales/de.json"
import studyMonitorDe from "pages/StudyMonitor/locales/de.json"

export const en = {
  shared: sharedEn,
  mainLayout: mainLayoutEn,
  settingsLayout: settingsLayoutEn,
  home: homeEn,
  detail: detailEn,
  course: courseEn,
  purchased: purchasedEn,
  favourites: favouritesEn,
  speaker: speakerEn,
  search: searchEn,
  test: testEn,
  watched: watchedEn,
  entryPoint: entryPointEn,
  login: loginEn,
  registration: registrationEn,
  emailConfirm: emailConfirmEn,
  settings: settingsEn,
  notifications: notificationsEn,
  forgotPassword: forgotPasswordEn,
  resetPassword: resetPasswordEn,
  welcome: welcomeEn,
  onboarding: onboardingEn,
  fallback: fallbackEn,
  allCourses: allCoursesEn,
  notSeenCourses: notSeenCoursesEn,
  allEpisodes: allEpisodesEn,
  allShortUnits: allShortUnitsEn,
  allIntros: allIntrosEn,
  channel: channelEn,
  studyMonitor: studyMonitorEn,
}

export const de = {
  shared: sharedDe,
  mainLayout: mainLayoutDe,
  settingsLayout: settingsLayoutDe,
  home: homeDe,
  detail: detailDe,
  course: courseDe,
  purchased: purchasedDe,
  favourites: favouritesDe,
  speaker: speakerDe,
  search: searchDe,
  test: testDe,
  watched: watchedDe,
  entryPoint: entryPointDe,
  login: loginDe,
  registration: registrationDe,
  emailConfirm: emailConfirmDe,
  settings: settingsDe,
  notifications: notificationsDe,
  forgotPassword: forgotPasswordDe,
  resetPassword: resetPasswordDe,
  welcome: welcomeDe,
  onboarding: onboardingDe,
  fallback: fallbackDe,
  allCourses: allCoursesDe,
  notSeenCourses: notSeenCoursesDe,
  allEpisodes: allEpisodesDe,
  allShortUnits: allShortUnitsDe,
  allIntros: allIntrosDe,
  channel: channelDe,
  studyMonitor: studyMonitorDe,
}
