import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
// services
import { getToken, removeToken, setToken } from "services/auth"
import i18n from "i18n"
// context
import AuthenticationContext from "./context"
// hooks
import useStrapiUser from "./hooks/useStrapiUser"
import useKalturaSession from "./hooks/useKalturaSession"
// components
import GlobalLoading from "shared/components/GlobalLoading"
// types
import { User } from "types/user"

type AuthenticationProviderProps = {
  children: ReactNode
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [authToken, setAuthToken] = useState(getToken())
  const [kalturaTokenState, setKalturaTokenState] = useState("")
  const queryClient = useQueryClient()
  const { user, setUser, isInitialLoading, refetchUser, isUserRefetching } = useStrapiUser(authToken)

  const { kalturaToken, handleSetKalturaToken, handleRemoveKalturaToken } = useKalturaSession()

  useEffect(() => {
    setKalturaTokenState(kalturaToken)
    if (!kalturaToken) {
      handleSetKalturaToken()
    }
  }, [kalturaToken])

  const handleAddAuthToken = useCallback(
    (token: string, email?: string) => {
      setAuthToken(token)
      setToken(token)
      handleSetKalturaToken((email ?? user.email) as string)
    },
    [setToken, user]
  )

  const handleRemoveAuthToken = useCallback(() => {
    setAuthToken("")
    removeToken()
    setUser({})
    handleRemoveKalturaToken()
    queryClient.clear()
    location.reload()
  }, [queryClient])

  useEffect(() => {
    if (user.locale) {
      i18n.changeLanguage(user.locale as string)
    }

    if (user.name) {
      // @ts-ignore
      window._paq?.push(["setUserId", user.name]) // set user id for Matomo tracking
    }
  }, [user.locale, user.name])

  const contextValue = useMemo(
    () => ({
      setAuthToken: handleAddAuthToken,
      handleRemoveAuthToken,
      authToken,
      setKalturaToken: handleSetKalturaToken,
      handleRemoveKalturaToken,
      kalturaToken: kalturaTokenState,
      user: user as User,
      setUser,
      refetchUser: () => {
        refetchUser()
      },
      isUserRefetching,
    }),
    [
      handleAddAuthToken,
      handleRemoveAuthToken,
      handleSetKalturaToken,
      handleRemoveKalturaToken,
      authToken,
      kalturaTokenState,
      user,
      setUser,
      refetchUser,
      isUserRefetching,
    ]
  )

  if (isInitialLoading) {
    return <GlobalLoading />
  }

  return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>
}

export default AuthenticationProvider
