// constants
import { HOME, FAVOURITES, PURCHASED, NOTIFICATION, WATCHED, STUDY_MONITOR } from "constants/routes"
// types
import { NavigationLink } from "./types"

export const navigationLinks: NavigationLink[] = [
  {
    tKey: "mainLayout:navigationMenu:navLinks:home",
    path: HOME,
    iconName: "home",
  },
  // * Note: Currently unused but retained for potential future use. *
  // {
  //   tKey: "mainLayout:navigationMenu:navLinks:search",
  //   path: SEARCH,
  //   iconName: "search",
  // },
  {
    tKey: "mainLayout:navigationMenu:navLinks:studyMonitor",
    path: STUDY_MONITOR,
    iconName: "monitor",
  },
  {
    tKey: "mainLayout:navigationMenu:navLinks:favourites",
    path: FAVOURITES,
    iconName: "watchlist",
  },
  {
    tKey: "mainLayout:navigationMenu:navLinks:purchased",
    path: PURCHASED,
    iconName: "basket",
  },
  {
    tKey: "mainLayout:navigationMenu:navLinks:watched",
    path: WATCHED,
    iconName: "checkOutlined",
  },
  {
    tKey: "mainLayout:navigationMenu:navLinks:notification",
    path: NOTIFICATION,
    iconName: "notification",
  },
]
