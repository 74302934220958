import { QueryClient } from "@tanstack/react-query"

const defaultConfiguration = {
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 minute - data remains fresh for this duration
      cacheTime: 300000, // 5 minutes - cache is preserved for this duration
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
}

export const createQueryClient = (config = defaultConfiguration) => new QueryClient(config)
const defaultQueryClient = createQueryClient()

export { defaultQueryClient as queryClient }
