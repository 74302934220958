import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
// hooks
import { useMostWatchedCourses } from "hooks/useMostWatchedCourses"
// components
import CardsCarousel from "shared/components/CardsCarousel"
// types
import { MostWatchedProps } from "./types"

const MostWatchedCourses = ({ sx }: MostWatchedProps) => {
  const { t } = useTranslation()
  const { isLoading, mostWatchedCards } = useMostWatchedCourses({ withCoursesTypesOnly: ["journal"] })

  return (
    <Box sx={sx}>
      <CardsCarousel isLoading={isLoading} cards={mostWatchedCards} title={t("studyMonitor:mostWatched:title")} />
    </Box>
  )
}

export default MostWatchedCourses
