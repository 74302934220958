import axios from "axios"
// services
import { getToken, removeKalturaToken, removeToken, setBeforeLoginUrl } from "./auth"
// constants
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
import { ENTRY_POINT } from "constants/routes"
// utils
import history from "utils/history"

const setDefaultAxios = () => {
  axios.defaults.baseURL = REACT_APP_STRAPI_API_URL
  axios.defaults.headers.common["Content-Type"] = "application/json"
}

const setupInterceptors = () => {
  axios.interceptors.request.use(
    (config: any) => {
      const token = getToken()

      if (token) {
        config.headers.Authorization = `Bearer ${getToken()}`
      }

      return config
    },
    (error) => {
      console.log("interceprots request error - ", error)
    }
  )
  axios.interceptors.response.use(
    (response) => {
      // simply return the response if there is no error

      return response
    },
    (error) => {
      setBeforeLoginUrl(location.href.split(location.origin)[1])
      if (error?.response?.status === 401) {
        removeToken()
        removeKalturaToken()
        location.reload()
        history.push(ENTRY_POINT)
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )
}

const HTTPService = {
  setDefaultAxios,
  setupInterceptors,
}

export default HTTPService
