import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { SwiperSlide } from "swiper/react"
// hooks
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
// components
import { CourseCard, ViewAllCard, BrandCard } from "shared/components/cards"
import Carousel from "shared/components/Carousel"
import { SkeletonLoadingRow } from "./components/SkeletonLoadingRow"
// constants
import { coursePath, courseEpisodePath } from "constants/routes"
// types
import { CardsCarouselProps } from "./types"

const CardsCarousel = ({
  isLoading,
  cards,
  showAllUrl,
  title = "",
  emptyStateTitle = "",
  hideIfEmpty,
  sx,
}: CardsCarouselProps) => {
  const { t } = useTranslation()
  const { isTablet } = useMUIBreakpoints()

  const isEmptyState = !isLoading && !cards.length

  return (
    <Box sx={sx}>
      {(!(isEmptyState && hideIfEmpty) || isLoading) && (
        <Carousel title={title} redirectTitleUrl={showAllUrl}>
          {isLoading && SkeletonLoadingRow({ isTablet })}
          {!isLoading && !isEmptyState && (
            <>
              {cards.map(
                (
                  {
                    title,
                    backgroundImage,
                    backgroundHoverMedia,
                    backgroundImageHash,
                    circleMaxValue,
                    circleCurrentValue,
                    episodesCount,
                    episodesWatched,
                    topTags,
                    bottomTags,
                    variant,
                    courseId,
                    episodeId,
                  },
                  idx
                ) => {
                  if (variant === "brandPrimary" || variant === "brandSecondary")
                    return (
                      <BrandCard
                        key={title + idx}
                        redirectUrl="/home"
                        title={title}
                        backgroundImage={backgroundImage}
                        buttonText="some button text"
                        variant={variant}
                      />
                    )

                  return (
                    <SwiperSlide key={title + idx}>
                      <CourseCard
                        redirectUrl={episodeId ? courseEpisodePath(courseId, episodeId) : coursePath(courseId)}
                        title={title}
                        backgroundImage={backgroundImage}
                        backgroundImageHash={backgroundImageHash}
                        backgroundHoverMedia={backgroundHoverMedia}
                        circleMaxValue={circleMaxValue}
                        circleCurrentValue={circleCurrentValue}
                        episodesCount={episodesCount}
                        episodesWatched={episodesWatched}
                        topTags={topTags}
                        bottomTags={bottomTags}
                        variant={variant}
                      />
                    </SwiperSlide>
                  )
                }
              )}
              {showAllUrl && (
                <SwiperSlide>
                  <ViewAllCard
                    redirectUrl={showAllUrl}
                    title={t("home:cardsCarousel:showAll")}
                    cardSize="viewAllCourse"
                  />
                </SwiperSlide>
              )}
            </>
          )}
          {isEmptyState && (
            <Typography
              sx={[
                (theme) => ({
                  ...theme.typography.h5,
                  [theme.breakpoints.down("sm")]: {
                    ...theme.typography.mobileH4,
                  },
                }),
              ]}
              variant="h5"
              color="black.lacquer.400"
            >
              {emptyStateTitle}
            </Typography>
          )}
        </Carousel>
      )}
    </Box>
  )
}

export default CardsCarousel
