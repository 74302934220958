import axios from "axios"
import qs from "qs"
// constants
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
// types
import { Pagination } from "types/api"
import {
  CourseProgress,
  CourseProgressAttributes,
  CourseProgressStatuses,
  EpisodePosition,
  EpisodePostionAttributes,
} from "types/courseProgress"
import { Localization } from "types/courses"

type GetSuperCourseProgressRequest = {
  superCourseId: number
}

export type GetSuperCourseProgressResponse = {
  data: {
    id: number
    attributes: {
      superEpisodeWatchedCount: number
      status: string
      courseWatchedDate: string
      cmeTestPassed: boolean
      cmeTestPassedDate: string
      createdAt: string
      updatedAt: string
      superCourse: { data: { id: number } }
      episodePosition: {
        data: {
          id: number
          attributes: {
            currentPosition: number
            maximumPosition: number
            isWatched: true
            createdAt: string
            updatedAt: string
            episode: {
              data: {
                id: number
                attributes: {
                  locale: string
                  localizations: {
                    data: Localization[]
                  }
                }
              }
            }
          }
        }
      }
    }
  }[]
  meta: Pagination
}

export const COURSE_WATCH_KEYS = Object.freeze({
  GET_SUPER_COURSE_PROGRESS: "superCourseProgress",
  GET_SUPER_COURSES_PROGRESS_WATCHED: "superCourseProgressWatched",
  GET_SUPER_COURSES_IN_PROGRESS: "superCoursesInProgress",
  GET_SUPER_COURSES_NOT_SEEN: "superCoursesNotSeen",
})

export const getSuperCourseProgress = async ({ superCourseId }: GetSuperCourseProgressRequest) => {
  const params = qs.stringify(
    {
      filters: {
        superCourse: {
          id: {
            $eq: superCourseId,
          },
        },
      },
      populate: {
        superCourse: {
          fields: ["id"],
        },
        episodePosition: {
          fields: ["id"],
          populate: {
            episode: {
              fields: ["id", "locale"],
              populate: {
                localizations: "all",
              },
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}super-course-progresses?${params}`

  const response = await axios.get<GetSuperCourseProgressResponse>(url)

  return response.data
}

type GetSuperCourseProgressesByStatusRequest = {
  status: CourseProgressStatuses
}

export type GetSuperCourseProgressesByStatusResponse = {
  data: CourseProgress<CourseProgressAttributes<EpisodePosition<Pick<EpisodePostionAttributes, "maximumPosition">>>>[]
  meta: Pagination
}

export const getSuperCourseProgressesByStatus = async ({ status }: GetSuperCourseProgressesByStatusRequest) => {
  const params = qs.stringify(
    {
      filters: {
        status: {
          $eq: status,
        },
      },
      populate: {
        superCourse: {
          fields: ["id"],
          populate: {
            courses: {
              fields: [
                "publishedAt",
                "courseType",
                "courseLength",
                "cmePoints",
                "cmePointsText",
                "courseName",
                "certified",
                "hashCourseCover",
                "hashCourseCoverLandscape",
                "locale",
              ],
              populate: {
                cover: {
                  fields: ["ext", "url"],
                },
                coverLandscape: {
                  fields: ["ext", "url"],
                },
                speaker: {
                  fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
                },
                episodes: {
                  fields: "id",
                },
                specialties: {
                  fields: ["name"],
                },
              },
            },
          },
        },
        episodePosition: {
          fields: "maximumPosition",
        },
      },
      sort: { updatedAt: "DESC" },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}super-course-progresses?${params}`

  const response = await axios.get<GetSuperCourseProgressesByStatusResponse>(url)

  return response.data
}

export type GetNotSeenSuperCoursesResponse = { id: number }[]

export const getNotSeenSuperCourses = async () => {
  const url = `${REACT_APP_STRAPI_API_URL}not-seen-super-courses`

  const response = await axios.get<GetNotSeenSuperCoursesResponse>(url)

  return response.data
}
