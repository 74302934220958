import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
// api
import { COURSE_KEYS, getMostWatchedCourses, GetMostWatchedCoursesResponse } from "api"
// components
import { Card, makeCardsForSlider } from "utils/cards"
// types
import { Language } from "types/i18n"
import { IFilterSpecialty } from "pages/Home/components/Filters/types"

const COURSES_CARDS_LIMIT = 10

type Props = { withCoursesTypesOnly?: string[]; specialties?: IFilterSpecialty[] }

export const useMostWatchedCourses = ({
  withCoursesTypesOnly,
  specialties,
}: Props): { mostWatchedCards: Card[] | []; isLoading: boolean } => {
  const { t, i18n } = useTranslation()

  const specialtiesIds = useMemo(
    () =>
      specialties?.reduce<number[]>((acc, { id, isActive }) => {
        if (isActive) acc.push(id)
        return acc
      }, []),
    [specialties]
  )

  const { data: mostWatchedCourses, isLoading: isCoursesLoading } = useQuery<GetMostWatchedCoursesResponse>({
    queryKey: [COURSE_KEYS.MOST_WATCHED_COURSES, i18n.language, withCoursesTypesOnly, specialtiesIds],
    queryFn: () => getMostWatchedCourses({ locale: i18n.language as Language, withCoursesTypesOnly, specialtiesIds }),
  })

  mostWatchedCourses?.data.sort(
    (a, b) =>
      (b?.attributes?.superCourse?.data?.attributes?.watchedCount ?? 0) -
      (a?.attributes?.superCourse?.data?.attributes?.watchedCount ?? 0)
  )

  mostWatchedCourses && (mostWatchedCourses.data = mostWatchedCourses?.data.slice(0, COURSES_CARDS_LIMIT))

  const courseCards = useMemo(
    () =>
      makeCardsForSlider({
        courses: mostWatchedCourses?.data || [],
        t,
      }) || [],
    [mostWatchedCourses]
  )

  return {
    isLoading: isCoursesLoading,
    mostWatchedCards: courseCards.filter((i) => i.courseId),
  }
}
