import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
// api
import { COURSE_KEYS, getNewestCourses, GetNewestCoursesResponse } from "api"
// types
import { Language } from "types/i18n"
import { Card, makeCardsForSlider } from "utils/cards"

export const useNewestCourses = (): { newestCoursesCards: Card[] | []; isLoading: boolean } => {
  const { t, i18n } = useTranslation()

  const { data: newestCourses, isLoading: isCoursesLoading } = useQuery<GetNewestCoursesResponse>({
    queryKey: [COURSE_KEYS.NEWEST_COURSES, i18n.language],
    queryFn: () =>
      getNewestCourses({ locale: i18n.language as Language, coursesLimit: 10, withCoursesTypesOnly: ["journal"] }),
  })

  const courseCards = useMemo(
    () =>
      makeCardsForSlider({
        courses: newestCourses?.data || [],
        t,
      }) || [],
    [newestCourses]
  )

  return {
    isLoading: isCoursesLoading,
    newestCoursesCards: courseCards.filter((i) => i.courseId),
  }
}
