import { useMemo } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
// hooks
import { useIntersectingCall } from "hooks/useIntersectingCall"
// api
import { COURSE_KEYS, getCoursesByCategories, GetCoursesByCategoriesResponse } from "api"
// types
import { Language } from "types/i18n"
import { Card, makeCardsForSlider } from "utils/cards"

type UseCourses = {
  specialty: string
  showAll?: boolean
  notShowJournalEpisodes?: boolean
  filterIds?: number[]
  enabled?: boolean
  courseTypesToShowOnly?: string[]
  searchValue?: string
}

export const useCourses = ({
  specialty,
  showAll,
  notShowJournalEpisodes,
  filterIds,
  enabled,
  courseTypesToShowOnly,
  searchValue,
}: UseCourses): {
  coursesCards: Card[] | []
  isLoading: boolean
  lastPlaceElement: (node: any) => void
  isFetchingNextPage: boolean
} => {
  const { t, i18n } = useTranslation()

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage: fetchMore,
  } = useInfiniteQuery<GetCoursesByCategoriesResponse>({
    queryKey: [COURSE_KEYS.COURSES_BY_CATEGORY, i18n.language, specialty, searchValue],
    queryFn: ({ pageParam = 1 }) =>
      getCoursesByCategories({ locale: i18n.language as Language, specialty, page: pageParam, searchValue }),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.pagination.page === lastPage.meta.pagination.pageCount) return false

      return lastPage.meta.pagination.page + 1
    },
    enabled,
  })

  const lastPlaceElement = useIntersectingCall({
    callInterrupter: isLoading || isFetching || isFetchingNextPage,
    conditionForCall: hasNextPage,
    callFn: fetchMore,
  })

  const coursesCards = useMemo(() => {
    let cards =
      data?.pages.flatMap((item) =>
        makeCardsForSlider({
          courses: item.data,
          t,
          showAll,
          notShowJournalEpisodes,
          courseTypesToShowOnly,
        })
      ) || []

    if (filterIds?.length && cards.length) {
      cards = cards.filter(({ courseId }) => !filterIds.includes(courseId))
    }

    return cards
  }, [data, filterIds])

  return {
    coursesCards,
    isLoading: isLoading || isFetching || isFetchingNextPage,
    lastPlaceElement,
    isFetchingNextPage,
  }
}
