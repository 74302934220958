import { createContext } from "react"
// types
import { User } from "types/user"

export type TAuthenticationContext = {
  setAuthToken: (token: string, email?: string) => void
  handleRemoveAuthToken: () => void
  user: User
  setKalturaToken: (userId?: string) => void
  handleRemoveKalturaToken: () => void
  kalturaToken: string
  authToken: string | null
  setUser: any
  refetchUser: () => void
  isUserRefetching?: boolean
}

const AuthenticationContext = createContext({
  user: {} as User,
  setKalturaToken: () => {},
  handleRemoveKalturaToken: () => {},
  kalturaToken: "",
  authToken: "",
  setAuthToken: () => {},
  handleRemoveAuthToken: () => {},
  setUser: () => {},
  refetchUser: () => {},
  isUserRefetching: false,
} as TAuthenticationContext)

export default AuthenticationContext
