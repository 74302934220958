import { Swiper, SwiperProps } from "swiper/react"
import { FreeMode } from "swiper"
import { Typography } from "@mui/material"
import { useMUIBreakpoints } from "hooks/useMUIBreakpoints"
//hooks
import useCarousel from "./hooks/UseCarousel"
//components
import Icon from "shared/components/Icon"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import { CarouselWrapper, NavButtons, NavButton, CarouselHeaderWrapper } from "./styles"
import NavLink from "../NavLink"

interface CarouselProps extends SwiperProps {
  redirectTitleUrl?: string
}

const Carousel = ({ children, title, redirectTitleUrl }: CarouselProps) => {
  const { isTablet } = useMUIBreakpoints()
  const { handleNextSlide, handleOnToFromEdge, handlePrevSlide, isFirstSlide, isLastSlide, swiperInit } = useCarousel()

  return (
    <CarouselWrapper>
      <CarouselHeaderWrapper>
        <Typography
          sx={[
            (theme) => ({
              ...theme.typography.h4,
              [theme.breakpoints.down("sm")]: {
                ...theme.typography.mobileH2,
              },
            }),
          ]}
          variant="h4"
          color="white.main"
        >
          {redirectTitleUrl ? (
            <NavLink
              to={redirectTitleUrl}
              sxLink={[
                (theme) => ({
                  fontSize: "inherit",
                  padding: 0,
                  color: theme.palette.white.main,
                }),
              ]}
            >
              {title}
            </NavLink>
          ) : (
            title
          )}
        </Typography>
        {!isTablet && (
          <NavButtons>
            <NavButton onClick={handlePrevSlide} disable={isFirstSlide}>
              <Icon name="chevronLeft" />
            </NavButton>
            <NavButton onClick={handleNextSlide} disable={isLastSlide}>
              <Icon name="chevronRight" />
            </NavButton>
          </NavButtons>
        )}
      </CarouselHeaderWrapper>
      <Swiper
        spaceBetween={isTablet ? 12 : 24}
        slidesPerView="auto"
        freeMode={true}
        modules={[FreeMode]}
        onSwiper={swiperInit}
        onToEdge={handleOnToFromEdge}
        onFromEdge={handleOnToFromEdge}
      >
        {children}
      </Swiper>
    </CarouselWrapper>
  )
}

export default Carousel
