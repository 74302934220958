import { Skeleton } from "@mui/material"
import { CARD_SIZES_MAPPING } from "constants/theme"
import { SwiperSlide } from "swiper/react"

export const SkeletonLoadingRow = ({ isTablet }: { isTablet: boolean }) => {
  const skeletonCourseWidth = isTablet ? CARD_SIZES_MAPPING.course.mobileWidth : CARD_SIZES_MAPPING.course.width
  const skeletonCourseHeight = isTablet ? CARD_SIZES_MAPPING.course.mobileHeight : CARD_SIZES_MAPPING.course.height

  const skeletonSingleShortVideoWidth = isTablet
    ? CARD_SIZES_MAPPING.singleShortVideo.mobileWidth
    : CARD_SIZES_MAPPING.singleShortVideo.width
  const skeletonSingleShortVideoHeight = isTablet
    ? CARD_SIZES_MAPPING.singleShortVideo.mobileHeight
    : CARD_SIZES_MAPPING.singleShortVideo.height

  return (
    <>
      <SwiperSlide>
        <Skeleton
          sx={{ borderRadius: "16px" }}
          variant="rounded"
          width={skeletonCourseWidth}
          height={skeletonCourseHeight}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton
          sx={{ borderRadius: "16px" }}
          variant="rounded"
          width={skeletonSingleShortVideoWidth}
          height={skeletonSingleShortVideoHeight}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton
          sx={{ borderRadius: "16px" }}
          variant="rounded"
          width={skeletonCourseWidth}
          height={skeletonCourseHeight}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton
          sx={{ borderRadius: "16px" }}
          variant="rounded"
          width={skeletonCourseWidth}
          height={skeletonCourseHeight}
        />
      </SwiperSlide>
    </>
  )
}
