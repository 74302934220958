import { useMutation } from "@tanstack/react-query"
import { useCallback, useState } from "react"
// api
import { kalturaSessionLogin, KalturaSessionLoginResponse } from "api"
// constants
import { getKalturaToken, removeKalturaToken, setKalturaToken as setKalturaTokenLocStor } from "services/auth"

function generateUserId(): string {
  const timestamp = Date.now() // Timestamp
  const randomPart = Math.random().toString(36).substring(2, 15) // Random string
  const screenSize = `${screen.width}x${screen.height}`

  return `${timestamp}-${randomPart}-${screenSize}`
}

const useKalturaSession = () => {
  const [kalturaToken, setKalturaToken] = useState(getKalturaToken() ?? "")

  const { mutate: fetchKalturaToken } = useMutation<KalturaSessionLoginResponse, unknown, string>({
    mutationFn: (userId) => kalturaSessionLogin(userId),
    onSuccess: (kalturaToken) => {
      setKalturaTokenLocStor(kalturaToken)
      setKalturaToken(kalturaToken || "")
    },
    onError: (err: any) => {
      console.error("Kaltura token was not received:", err)
    },
  })

  const handleSetKalturaToken = useCallback((userId?: string) => {
    fetchKalturaToken(userId || generateUserId())
  }, [])

  const handleRemoveKalturaToken = useCallback(() => {
    removeKalturaToken()
  }, [])

  return {
    kalturaToken,
    handleSetKalturaToken,
    handleRemoveKalturaToken,
  }
}

export default useKalturaSession
