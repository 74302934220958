import axios from "axios"
import qs from "qs"
// constants
import { REACT_APP_STRAPI_API_URL } from "constants/endpoints"
import { languagesTransformer } from "i18n"
import { PAGE_SIZE } from "constants/paginations"
// types
import { Pagination } from "types/api"
import { Language } from "types/i18n"
import { EpisodeAttributes, Episode, Speaker, SpeakerAttributes, Course, CourseAttributes } from "types/courses"
import { Nullable } from "types/generics"

export type SliderCourse = Course<
  Omit<
    CourseAttributes<
      Nullable<
        Episode<
          Omit<
            EpisodeAttributes,
            "episodeNumber" | "kalturaVideoId" | "episodeCoverLandscape" | "localizations" | "episodeLength"
          >
        >[]
      >,
      Nullable<Speaker<Omit<SpeakerAttributes, "speakerPicture">>>
    >,
    "intro" | "videoLanguags" | "coursePartner" | "courseDescription" | "superCourse" | "courseLength" | "cmePoints"
  >
>

export type GetAllCoursesRequest = {
  locale?: Language
}

export type GetAllCoursesResponse = {
  data: Course<
    Omit<
      CourseAttributes<{ id: number }[], Speaker<Omit<SpeakerAttributes, "speakerPicture">>>,
      "coursePartner" | "courseLength" | "cmePoints" | "courseDescription" | "episodes"
    >
  >[]
  meta: Pagination
}

export type GetCoursesBySuperCoursesRequest = {
  superCoursesIds?: number[]
  specialtiesIds?: number[]
  locale?: Language
  page?: number
  pageSize?: number
}

export type GetCoursesBySuperCoursesResponse = {
  data: SliderCourse[]
  meta: {
    pagination: Pagination
  }
}

export type GetCoursesByCategoriesRequest = {
  specialty: string
  locale?: Language
  page?: number
  pageSize?: number
  searchValue?: string
}

export type GetCoursesByCategoriesResponse = {
  data: SliderCourse[]
  meta: {
    pagination: Pagination
  }
}

export type GetCoursesBySpeakerIdRequest = {
  speakerId: number
  locale?: Language
}

export type GetCoursesBySpeakerIdResponse = {
  data: SliderCourse[]
  meta: {
    pagination: Pagination
  }
}

export type GetCommonCoursesRequest = {
  locale?: Language
  filterValue?: string
  page?: number
  pageSize?: number
}

export type GetCommonCoursesResponse = {
  data: SliderCourse[]
  meta: {
    pagination: Pagination
  }
}

export type GetSingleCoursesRequest = {
  locale?: Language
  filterValue?: string
  page?: number
  pageSize?: number
}

export type GetSingleCoursesResponse = {
  data: SliderCourse[]
  meta: {
    pagination: Pagination
  }
}

export type GetNewestCoursesRequest = {
  locale?: Language
  coursesLimit?: number
  withCoursesTypesOnly?: string[]
}

export type GetNewestCoursesResponse = {
  data: Course<
    Omit<
      CourseAttributes<
        Nullable<
          Episode<
            Omit<
              EpisodeAttributes,
              "episodeNumber" | "kalturaVideoId" | "episodeCoverLandscape" | "localizations" | "episodeLength"
            >
          >[]
        >,
        Nullable<Speaker<Omit<SpeakerAttributes, "speakerPicture">>>
      >,
      "intro" | "videoLanguags" | "coursePartner" | "courseDescription" | "courseLength" | "cmePoints"
    >
  >[]
  meta: Pagination
}

export type GetMostWatchedCoursesRequest = {
  locale?: Language
  withCoursesTypesOnly?: string[]
  specialtiesIds?: number[]
}

export type GetMostWatchedCoursesResponse = {
  data: Course<
    Omit<
      CourseAttributes<
        Nullable<
          Episode<
            Omit<
              EpisodeAttributes,
              "episodeNumber" | "kalturaVideoId" | "episodeCoverLandscape" | "localizations" | "episodeLength"
            >
          >[]
        >,
        Nullable<Speaker<Omit<SpeakerAttributes, "speakerPicture">>>
      >,
      "intro" | "videoLanguags" | "coursePartner" | "courseDescription" | "courseLength" | "cmePoints"
    >
  >[]
  meta: Pagination
}

export const getAllCourses = async ({ locale = "en" }: GetAllCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      sort: { id: "DESC" },
      locale: languagesTransformer[_locale],

      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
        "locale",
      ],
      populate: {
        cover: {
          fields: ["ext", "url"],
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        superCourse: { fields: ["id"] },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/courses?${params}`

  const response = await axios.get<GetAllCoursesResponse>(url)

  return response.data
}

export const getCoursesBySuperCourses = async ({
  locale = "en",
  superCoursesIds,
  specialtiesIds,
  page = 1,
  pageSize = PAGE_SIZE,
}: GetCoursesBySuperCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      sort: { id: "DESC" },
      locale: languagesTransformer[_locale],

      filters: {
        superCourse: {
          id: {
            $in: superCoursesIds,
          },
        },
        specialties: {
          id: {
            $in: specialtiesIds,
          },
        },
      },
      pagination: {
        page,
        pageSize,
      },
      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        cover: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        specialties: {
          fields: ["name"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}courses?${params}`

  const response = await axios.get<GetCoursesBySuperCoursesResponse>(url)

  return response.data
}

export const getCoursesByCategories = async ({
  specialty,
  locale = "en",
  page = 1,
  pageSize = PAGE_SIZE,
  searchValue,
}: GetCoursesByCategoriesRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        specialties: {
          name: {
            $eq: specialty,
          },
        },
        ...(searchValue
          ? {
              $or: [
                {
                  courseName: {
                    $containsi: searchValue,
                  },
                },
                {
                  courseDescription: {
                    $containsi: searchValue,
                  },
                },
                {
                  courseKeywords: {
                    $containsi: searchValue,
                  },
                },
                {
                  specialties: {
                    name: {
                      $containsi: searchValue,
                    },
                  },
                },
                {
                  episodes: {
                    episodeName: {
                      $containsi: searchValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerFirstName: {
                      $containsi: searchValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerLastName: {
                      $containsi: searchValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerTitle: {
                      $containsi: searchValue,
                    },
                  },
                },
              ],
            }
          : {}),
      },
      pagination: {
        page,
        pageSize,
      },
      sort: { publishedAt: "DESC" },
      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        cover: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}courses?${params}`

  const response = await axios.get<GetCoursesByCategoriesResponse>(url)

  return response.data
}

export const getCoursesBySpeakerId = async ({ speakerId, locale = "en" }: GetCoursesBySpeakerIdRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      filters: {
        speaker: {
          id: {
            $eq: speakerId,
          },
        },
      },
      sort: { publishedAt: "DESC" },
      fields: [
        "publishedAt",
        "courseType",
        "courseLength",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        cover: {
          fields: ["ext", "url"],
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}courses?${params}`

  const response = await axios.get<GetCoursesBySpeakerIdResponse>(url)

  return response.data
}

export const getCommonCourses = async ({
  locale = "en",
  filterValue,
  page = 1,
  pageSize = PAGE_SIZE,
}: GetCommonCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        $and: [
          {
            courseType: {
              $ne: "shortUnit",
            },
          },
          {
            courseType: {
              $ne: "journal",
            },
          },
        ],
        ...(filterValue
          ? {
              $or: [
                {
                  courseName: {
                    $containsi: filterValue,
                  },
                },
                {
                  courseDescription: {
                    $containsi: filterValue,
                  },
                },
                {
                  courseKeywords: {
                    $containsi: filterValue,
                  },
                },
                {
                  specialties: {
                    name: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerFirstName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerLastName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerTitle: {
                      $containsi: filterValue,
                    },
                  },
                },
              ],
            }
          : {}),
      },
      sort: { publishedAt: "DESC" },
      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        cover: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        specialties: {
          fields: ["name"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}courses?${params}`

  const response = await axios.get<GetCommonCoursesResponse>(url)

  return response.data
}

export const getSingleCourses = async ({
  locale = "en",
  filterValue,
  page = 1,
  pageSize = PAGE_SIZE,
}: GetSingleCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language
  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        courseType: {
          $eq: ["shortUnit", "journal"],
        },
        episodes: {
          id: {
            $notNull: true,
          },
        },
        ...(filterValue
          ? {
              $or: [
                {
                  courseKeywords: {
                    $containsi: filterValue,
                  },
                },
                {
                  courseName: {
                    $containsi: filterValue,
                  },
                },
                {
                  courseDescription: {
                    $containsi: filterValue,
                  },
                },
                {
                  specialties: {
                    name: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerFirstName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerLastName: {
                      $containsi: filterValue,
                    },
                  },
                },
                {
                  speaker: {
                    speakerTitle: {
                      $containsi: filterValue,
                    },
                  },
                },
              ],
            }
          : {}),
      },
      sort: { publishedAt: "DESC" },
      fields: [
        "publishedAt",
        "courseType",
        "courseLength",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "certified",
        "hashCourseCover",
        "hashCourseCoverLandscape",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        cover: {
          fields: ["url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        specialties: {
          fields: ["name"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `${REACT_APP_STRAPI_API_URL}courses?${params}`

  const response = await axios.get<GetSingleCoursesResponse>(url)

  return response.data
}

export const getNewestCourses = async ({
  locale = "en",
  coursesLimit = 10,
  withCoursesTypesOnly,
}: GetNewestCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      sort: ["publishedAt:DESC"],
      locale: languagesTransformer[_locale],
      pagination: { limit: coursesLimit },
      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "hashCourseCover",
        "hashCourseCoverLandscape",
        "locale",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        cover: {
          fields: ["ext", "url"],
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        specialties: {
          fields: ["name"],
        },
      },
      filters: {
        ...(withCoursesTypesOnly && {
          courseType: {
            $eq: withCoursesTypesOnly,
          },
        }),
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/courses?${params}`

  const response = await axios.get<GetNewestCoursesResponse>(url)

  return response.data
}

export const getMostWatchedCourses = async ({
  locale = "en",
  withCoursesTypesOnly,
  specialtiesIds,
}: GetMostWatchedCoursesRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],

      fields: [
        "publishedAt",
        "courseType",
        "courseLengthText",
        "cmePoints",
        "cmePointsText",
        "courseName",
        "hashCourseCover",
        "hashCourseCoverLandscape",
        "locale",
      ],
      populate: {
        episodes: {
          fields: ["episodeLength", "episodeLengthText", "episodeName", "publishedAt", "hashEpisodeCover"],
          populate: {
            episodeCover: {
              fields: ["ext", "url"],
            },
          },
        },
        cover: {
          fields: ["ext", "url"],
        },
        coverLandscape: {
          fields: ["ext", "url"],
        },
        speaker: {
          fields: ["speakerTitle", "speakerFirstName", "speakerLastName"],
        },
        specialties: {
          fields: ["name"],
        },
        superCourse: { fields: ["id", "watchedCount"] },
      },
      filters: {
        ...(withCoursesTypesOnly && {
          courseType: {
            $eq: withCoursesTypesOnly,
          },
        }),
        ...(specialtiesIds && {
          specialties: {
            id: {
              $in: specialtiesIds,
            },
          },
        }),
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/courses?${params}`

  const response = await axios.get<GetMostWatchedCoursesResponse>(url)

  return response.data
}
