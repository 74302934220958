import { TFunction } from "react-i18next"
// api
import { SliderCourse, TEpisode, TIntro } from "api"
// constants
import { COURSE_TYPE_TRANSFORMER, newTag } from "constants/courses"
import { REACT_APP_STRAPI_URL } from "constants/endpoints"
// utils
import { addMonths, isBefore } from "./date"
// types
import { CardSizes } from "types/theme"
import { TagsSetTag } from "shared/components/cards/components/TagsSet/types"

export type Card = {
  courseId: number
  title: string
  backgroundImage: string
  backgroundImageHash?: string
  variant: CardSizes
  episodeId?: number
  backgroundHoverMedia?: string
  circleMaxValue?: number
  circleCurrentValue?: number
  episodesCount?: number
  episodesWatched?: number
  topTags?: TagsSetTag[]
  bottomTags?: TagsSetTag[]
}

type MakeCardsForSlider = {
  t: TFunction<"translation", undefined>
  courses?: SliderCourse[]
  showAll?: boolean
  notShowJournalEpisodes?: boolean
  courseTypesToShowOnly?: string[]
}

type MakeSingleCardsForSlider = {
  t: TFunction<"translation", undefined>
  courses?: SliderCourse[]
}

type MakeEpisodesForSlider = {
  t: TFunction<"translation", undefined>
  episodes?: TEpisode[]
}

type MakeIntrosForSlider = {
  t: TFunction<"translation", undefined>
  intros?: TIntro[]
}

export const makeCardsForSlider = ({
  t,
  courses,
  showAll,
  notShowJournalEpisodes,
  courseTypesToShowOnly,
}: MakeCardsForSlider): Card[] => {
  return (
    courses?.reduce(
      (
        acc,
        {
          id: courseId,
          attributes: {
            courseName,
            courseType,
            courseLengthText,
            publishedAt,
            cmePointsText,
            certified,
            episodes,
            hashCourseCover,
            hashCourseCoverLandscape,
            cover,
            coverLandscape,
            speaker,
            specialties,
          },
        }
      ) => {
        const coverUrl = cover?.data?.attributes?.url
        const coverLandscapeUrl = coverLandscape?.data?.attributes?.url
        const isNewCourse = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())
        const isSingleVideoCourse = courseType === "shortUnit"
        const { speakerTitle = "", speakerFirstName = "", speakerLastName = "" } = speaker?.data?.attributes || {}

        const episodesData = episodes?.data

        const isShowJournalEpisodes = !(notShowJournalEpisodes && courseType === "journal")

        const _course = [] as Card[]
        let _episodes = [] as Card[]

        if (courseTypesToShowOnly) {
          if (!courseTypesToShowOnly.includes(courseType)) return acc
        }

        if ((isSingleVideoCourse || showAll) && isShowJournalEpisodes && episodesData) {
          _episodes = episodesData?.map(
            ({
              id: episodeId,
              attributes: { episodeLengthText, episodeName, publishedAt, hashEpisodeCover, episodeCover },
            }) => {
              const isNewEpisode = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())
              const topTags: TagsSetTag[] = [{ title: episodeLengthText, variant: "primary" }]

              const bottomTags: TagsSetTag[] = [
                {
                  title: isSingleVideoCourse ? t(`shared:courseTypes:${courseType}`) : "episode",
                  variant: "primary",
                },
              ]

              if (isNewEpisode) {
                topTags.unshift(newTag(t))
              }

              return {
                courseId,
                episodeId,
                title: episodeName,
                backgroundImage: REACT_APP_STRAPI_URL + episodeCover?.data?.attributes?.url,
                backgroundImageHash: hashEpisodeCover,
                topTags,
                bottomTags,
                variant: "singleShortVideo",
              }
            }
          )
        }

        if (!isSingleVideoCourse) {
          const topTags: TagsSetTag[] = [{ title: courseLengthText, variant: "primary" }]
          const bottomTags: TagsSetTag[] = [
            {
              title: t(`shared:courseTypes:${courseType}`),
              variant: "primary",
            },
          ]

          if (isNewCourse) {
            bottomTags.unshift(newTag(t))
          }

          if (certified) {
            bottomTags.push({ title: cmePointsText, variant: "primary" })
          }

          if (courseType === "expertCourse") {
            bottomTags.push({ title: `${speakerTitle} ${speakerFirstName} ${speakerLastName}`, variant: "primary" })
          }

          if (specialties?.data?.length) {
            specialties.data.forEach(({ attributes: { name } }) => {
              bottomTags.push({
                variant: "primary",
                title: name,
              })
            })
          }

          _course[0] = {
            courseId,
            title: courseName,
            backgroundImage: REACT_APP_STRAPI_URL + (courseType === "journal" ? coverUrl : coverLandscapeUrl),
            backgroundImageHash: courseType === "journal" ? hashCourseCover : hashCourseCoverLandscape,
            topTags,
            bottomTags,
            variant: COURSE_TYPE_TRANSFORMER[courseType as keyof typeof COURSE_TYPE_TRANSFORMER],
          }
        }

        return [...acc, ..._course, ..._episodes]
      },
      [] as Card[]
    ) || []
  )
}

export const makeSingleCardsForSlider = ({ t, courses }: MakeSingleCardsForSlider): Card[] => {
  return (
    courses?.reduce(
      (acc, { id: courseId, attributes: { courseType, episodes, specialties, cover, hashCourseCover } }) => {
        const episodesData = episodes?.data

        let _episodes = [] as Card[]

        if (episodesData) {
          // Take only the first episode to show in slider
          _episodes = [episodesData[0]]?.map(
            ({ id: episodeId, attributes: { episodeLengthText, episodeName, publishedAt } }) => {
              const isNewEpisode = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())

              const bottomTags: TagsSetTag[] = [
                {
                  title: t(`shared:courseTypes:${courseType}`),
                  variant: "primary",
                },
              ]
              const topTags: TagsSetTag[] = [{ title: episodeLengthText, variant: "primary" }]

              if (isNewEpisode) {
                topTags.unshift(newTag(t))
              }

              if (specialties?.data?.length) {
                specialties.data.forEach(({ attributes: { name } }) => {
                  bottomTags.push({
                    variant: "primary",
                    title: name,
                  })
                })
              }

              return {
                courseId,
                episodeId,
                title: episodeName,
                backgroundImage: REACT_APP_STRAPI_URL + cover?.data?.attributes?.url,
                backgroundImageHash: hashCourseCover,
                topTags,
                bottomTags,
                variant: "singleShortVideo",
              }
            }
          )
        }

        return [...acc, ..._episodes]
      },
      [] as Card[]
    ) || []
  )
}

export const makeEpisodeCardsForSlider = ({ episodes, t }: MakeEpisodesForSlider): Card[] => {
  return episodes?.map(
    ({
      id: episodeId,
      attributes: {
        episodeLengthText,
        episodeName,
        publishedAt,
        hashEpisodeCover,
        episodeCover,
        course: {
          data: {
            id: courseId,
            attributes: { specialties },
          },
        },
      },
    }) => {
      const isNewEpisode = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())

      const bottomTags: TagsSetTag[] = [
        {
          title: "episode",
          variant: "primary",
        },
      ]
      const topTags: TagsSetTag[] = [{ title: episodeLengthText, variant: "primary" }]

      if (isNewEpisode) {
        topTags.unshift(newTag(t))
      }

      if (specialties.data?.length) {
        specialties.data.forEach(({ attributes: { name } }) => {
          bottomTags.push({
            variant: "primary",
            title: name,
          })
        })
      }

      return {
        courseId,
        episodeId,
        title: episodeName,
        backgroundImage: REACT_APP_STRAPI_URL + episodeCover?.data?.attributes?.url,
        backgroundImageHash: hashEpisodeCover,
        topTags,
        bottomTags,
        variant: "singleShortVideo",
      }
    }
  ) as Card[]
}

export const makeIntroCardsForSlider = ({ intros, t }: MakeIntrosForSlider): Card[] => {
  return intros?.map(
    ({
      attributes: {
        publishedAt,
        introName,
        course: {
          data: {
            id,
            attributes: { specialties },
          },
        },
        introCoverHash,
        introCover,
      },
    }) => {
      const isNewIntro = isBefore(Date.now(), addMonths(1, new Date(publishedAt)).getTime())

      const bottomTags: TagsSetTag[] = [
        {
          title: "intro",
          variant: "primary",
        },
      ]
      const topTags: TagsSetTag[] = []

      if (isNewIntro) {
        topTags.unshift(newTag(t))
      }

      if (specialties.data?.length) {
        specialties.data.forEach(({ attributes: { name } }) => {
          bottomTags.push({
            variant: "primary",
            title: name,
          })
        })
      }

      return {
        courseId: id,
        title: introName,
        backgroundImage: REACT_APP_STRAPI_URL + introCover?.data?.attributes?.url,
        backgroundImageHash: introCoverHash,
        topTags,
        bottomTags,
        variant: "singleShortVideo",
      }
    }
  ) as Card[]
}
